import React, { useEffect, useRef, useState } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const PhoneNumberInput = ({ handlePhoneNumberInput, setSelectedCountryCode, errorState }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const inputElement = inputRef.current;

    const iti = intlTelInput(inputElement, {
      initialCountry: "us",
      separateDialCode: true,
    });

    const initialCountryData = iti.getSelectedCountryData();
    setSelectedCountryCode(initialCountryData.dialCode);

    const handleCountryChange = () => {
      const countryData = iti.getSelectedCountryData();
      setSelectedCountryCode(countryData.dialCode);
    };

    inputElement.addEventListener("countrychange", handleCountryChange);

    // Clean up function
    return () => {
      inputElement.removeEventListener("countrychange", handleCountryChange);
      iti.destroy();
    };
  }, [setSelectedCountryCode]);

  const handleInput = (e) => {
    const rawValue = e.target.value;
    setInputValue(rawValue.replace(/x/g, ''));
    handlePhoneNumberInput(rawValue.replace(/x/g, ''));
    // console.log(rawValue.replace(/x/g, ''));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      setInputValue((prev) => prev.slice(0, -1));
      handlePhoneNumberInput(inputValue);
    }
  };

  const isSmallScreen = window.innerWidth <= 768;

  return (
    <>
      <div className="form-group col-12" >
                          <div className="form-label-group">
                            <label className="form-label" for="default-01">
                              Phone Number <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <div className="form-control-wrap">
                          <input
                            ref={inputRef}
                            type="tel"
                            id="contact"
                            name="contact"
                            className="form-control form-control-lg"
                            value={inputValue}
                            placeholder="Enter phone number"
                            onInput={handleInput}
                            onKeyDown={handleKeyDown}
                            style={{
                              ...errorState && { borderColor: "red" },
                              width: isSmallScreen ? "100%" : "400px",
                              maxWidth: "400px",
                            }}

                          />

 </div>
                        </div>
    {errorState && (
      <div
        className=" bg-white pl-2 rounded mb-4 mt-1 flex"
        style={{
          border: "1px solid red",
          borderColor: "red",
          color: "red",
          
        }}
      >
        <span className="icon mr-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            style={{ height: "5%", width: "5%", paddingLeft: "4px" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
        <span className="text-red-500 font-bold" style={{ fontSize: "12px" }}>
          This is a required field
        </span>
      </div>
    )}

</>
  );
};

export default PhoneNumberInput;
