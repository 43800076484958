import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Affiliates = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Partnership', href: '/partners', active: false },
    { label: 'Affiliates', href: '#', active: true }
  ];

const styles = {
    container: {
      fontFamily: "'Poppins', sans-serif",
      padding: "20px",
      maxWidth: "1200px",
      margin: "0 auto",
      color: "#333",
    },
    header: {
      textAlign: "center",
      marginBottom: "30px",
    },
    section: {
      marginBottom: "20px",
    },
    cardContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      gap: "20px",
    },
    card: {
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      backgroundColor: "#fff",
      textAlign: "center",
      transition: "transform 0.3s, box-shadow 0.3s",
    },
    cardHover: {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
    cardIcon: {
      fontSize: "2rem",
      color: "#007BFF",
      marginBottom: "10px",
    },
    cardTitle: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    cardText: {
      fontSize: "1rem",
      lineHeight: "1.6",
    },
    button: {
      display: "block",
      textAlign: "center",
      margin: "30px auto 10px",
      padding: "12px 20px",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#800020",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      textDecoration: "none",
      width: "fit-content",
    },
    policyLink: {
      display: "block",
      textAlign: "center",
      margin: "10px auto 30px",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#800020",
      textDecoration: "none",
      cursor: "pointer",
    },
    footer: {
      textAlign: "center",
      marginTop: "20px",
    },
  };

  return (
    <React.Fragment>
            <Helmet>
                <title>Affiliates | Barter Funds</title>
                <meta name="description" content="Review the Affiliate Policy of Barter Funds. Understand the terms, conditions, and guidelines for participating in our affiliate program." />
                <meta name="keywords" content="Barter Funds, Affiliate Program, Affiliate Policy, Digital Assets, Referral Program, Earn Money" />
                <meta name="author" content="Barter Funds" />
                <meta property="og:title" content="Affiliats | Barter Funds" />
                <meta property="og:description" content="Detailed information about Barter Funds' Affiliate Policy, including how to join and the benefits of being an affiliate." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://barterfunds.com/affiliate-policy" />
                <meta property="og:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta property="og:site_name" content="Barter Funds" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Affiliate Policy | Barter Funds" />
                <meta name="twitter:description" content="Learn more about the terms and conditions of Barter Funds' affiliate program." />
                <meta name="twitter:image" content="https://barterfunds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta name="twitter:site" content="@BarterFunds" />
            </Helmet>
      <Header />
      <Head title="Affiliate | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h2" className="fw-normal">
              Affiliates
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <div style={styles.container}>
      <header style={styles.header}>
        <h1>Welcome to BarterFunds Affiliates!</h1>
        <p>
          Earn rewards by sharing the benefits of BarterFunds with your network.
          Join our affiliate program and grow with us!
        </p>
      </header>

      <section style={styles.section}>
        <h2 style={styles.header}>Why Join the Affiliate Program?</h2>
        <div style={styles.cardContainer}>
          <div style={styles.card}>
            <div style={styles.cardIcon}>💰</div>
            <div style={styles.cardTitle}>Attractive Commissions</div>
            <p style={styles.cardText}>
              Earn 50 cedis per successful referral when your referred user
              completes qualifying transactions.
            </p>
          </div>

          <div style={styles.card}>
            <div style={styles.cardIcon}>⚡</div>
            <div style={styles.cardTitle}>Easy to Join</div>
            <p style={styles.cardText}>
              Quick sign-up process with a user-friendly dashboard to track your
              referrals and earnings.
            </p>
          </div>

          <div style={styles.card}>
            <div style={styles.cardIcon}>📈</div>
            <div style={styles.cardTitle}>Marketing Support</div>
            <p style={styles.cardText}>
              Get access to banners, email templates, and social media content
              to promote BarterFunds.
            </p>
          </div>

          <div style={styles.card}>
            <div style={styles.cardIcon}>🔒</div>
            <div style={styles.cardTitle}>Trusted Brand</div>
            <p style={styles.cardText}>
              Partner with a platform known for its innovation, security, and
              customer satisfaction.
            </p>
          </div>
        </div>
      </section>

      <section style={styles.section}>
        <h2 style={styles.header}>How It Works</h2>
        <div style={styles.cardContainer}>
          <div style={styles.card}>
            <div style={styles.cardIcon}>1️⃣</div>
            <div style={styles.cardTitle}>Sign Up</div>
            <p style={styles.cardText}>
              Register for the program and get your unique referral link.
            </p>
          </div>

          <div style={styles.card}>
            <div style={styles.cardIcon}>2️⃣</div>
            <div style={styles.cardTitle}>Promote</div>
            <p style={styles.cardText}>
              Share your referral link across social media, blogs, and email.
            </p>
          </div>

          <div style={styles.card}>
            <div style={styles.cardIcon}>3️⃣</div>
            <div style={styles.cardTitle}>Earn</div>
            <p style={styles.cardText}>
              Get commissions for every user who signs up and completes a
              qualifying transaction.
            </p>
          </div>
        </div>
      </section>

      <a href="/signup" style={styles.button}>
        Join the Affiliate Program Now
      </a>

      <a href="/affiliate-policy" style={styles.policyLink}>
        Click to Read Our Affiliate Policy
      </a>

      <footer style={styles.footer}>
        <p>
          📧 Questions? Contact us at{" "}
          <a href="mailto:affiliates@barterfunds.com">affiliates@barterfunds.com</a>
        </p>
      </footer>
    </div>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Affiliates;
