import React from 'react';
import { Helmet } from 'react-helmet';
import { FaFacebook, FaTwitter, FaWhatsapp, FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';

import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Contact = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Company', href: '/company', active: false },
    { label: 'Contact', href: '#', active: true }
  ];

  const helpOptions = [
    {
      icon: '🗨️', 
      title: 'WhatsApp Chat',
      description: 'Quick help',
      link: 'https://wa.me/message/54O3YEIW6MH4P1',
    },
    {
      icon: '💬', 
      title: 'Telegram Chat',
      description: 'Fast connect',
      link: 'https://t.me/barterfunds',
    },
    {
      icon: '📄', 
      title: 'Knowledge Base',
      description: 'Quick tips',
      link: '/knowledge-base',
    },
    {
      icon: '❓', 
      title: 'FAQ',
      description: 'Find answers',
      link: '/faq',
    },
    {
      icon: '👥', 
      title: 'Community Forum',
      description: 'Join talks',
      link: 'https://whatsapp.com/channel/0029Va86SiyCRs1rKZ7iFl47',
    },
    {
      icon: '🎫', 
      title: 'Support Ticket',
      description: 'Get solved',
      link: '/user/support',
    },
  ];

  const contactOptions = [
    {
      icon: '📍', 
      title: 'Barter  Funds HQ.',
      description: `Lame Dwaahe Street, Adenta Housing Down.
Accra - Ghana`,
    },
    {
      icon: '📞', 
      title: 'Telephone',
      description: `(+233) 0598870911
(+233) 0530467164`,
    },
    {
      icon: '📧', 
      title: 'Business Inquiries',
      description: `support@barterfunds.com
business@barterfunds.com`,
    },
  ];

  return (
    <React.Fragment>

      <Helmet>
        <title>Contact Us | Barter Funds</title>
        <meta name="description" content="Get in touch with Barter Funds for any questions, support, or business inquiries. Our team is here to help you with your digital financial needs. Reach out via live chat, email, or through our social media channels." />
        <meta name="keywords" content="Contact Barter Funds, customer support, business inquiries, help, digital financial services, e-currency exchange" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Barter Funds" />
        <meta property="og:title" content="Contact Us | Barter Funds" />
        <meta property="og:description" content="Need assistance? Contact Barter Funds' support team for help with your account, transactions, or any other inquiries. We're here to assist you with your digital financial needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.barterfunds.com/contact-us" />
        <meta property="og:image" content="https://www.barterfunds.com/images/contact-us-thumbnail.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us | Barter Funds" />
        <meta name="twitter:description" content="Reach out to Barter Funds for any questions or support you need. Our team is available via live chat, email, or social media to assist you." />
        <meta name="twitter:image" content="https://www.barterfunds.com/images/contact-us-thumbnail.jpg" />
      </Helmet>

      <Header />
      <Head title="Contact Us | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h1" className="fw-normal">
              Contact Us
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>

          
            
            <div style={{ backgroundColor: '#f7f9fc', padding: '20px' }}>
      {/* Contact Section */}
      <section style={{ marginTop: '10px' }}>
      <h2 style={{ color: '#1d1d1d', marginBottom: '20px' }}>Reach out to us</h2>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '20px',
          }}
        >
          {contactOptions.map((option, index) => (
            <div
              key={index}
              style={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div
                style={{
                  fontSize: '40px',
                  marginBottom: '10px',
                  color: '#28a745', // Green color for icons
                }}
              >
                {option.icon}
              </div>
              <h3 style={{ margin: '0 0 10px', fontSize: '18px', color: '#333' }}>
                {option.title}
              </h3>
              <p style={{ margin: 0, whiteSpace: 'pre-wrap', color: '#666' }}>
                {option.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Help Section */}
      <section>
        <h2 style={{ color: '#1d1d1d', marginBottom: '20px' }}>More ways we can help</h2>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '20px',
          }}
        >
          {helpOptions.map((option, index) => (
            <a
              key={index}
              href={option.link}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '20px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <div
                style={{
                  fontSize: '30px',
                  marginRight: '15px',
                  color: '#0070ba',
                }}
              >
                {option.icon}
              </div>
              <div>
                <h3 style={{ margin: '0 0 5px', fontSize: '18px', color: '#333' }}>
                  {option.title}
                </h3>
                <p style={{ margin: 0, color: '#666' }}>{option.description}</p>
              </div>
            </a>
          ))}
        </div>
      </section>


      

      {/* Footer Section */}
      <div
        style={{
          marginTop: '60px',
          textAlign: 'center',
          backgroundColor: '#f7f9fc',
          padding: '20px 0',
        }}
      >
        <h3 style={{ color: '#333', marginBottom: '10px' }}>Get in touch</h3>
        <p style={{ color: '#666', marginBottom: '20px' }}>
          Follow us for updates, news, tips, and exclusive community engagement..
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', fontSize: '24px' }}>
          <a href="https://facebook.com/barterfunds" style={{ color: '#4267B2' }}>
            <FaFacebook />
          </a>
          <a href="https://twitter.com/barterfunds" style={{ color: '#1DA1F2' }}>
            <FaTwitter />
          </a>
          <a href="https://whatsapp.com/channel/0029Va86SiyCRs1rKZ7iFl47" style={{ color: '#25D366' }}>
            <FaWhatsapp />
          </a>
          <a href="https://linkedin.com/company/barterfunds" style={{ color: '#0077b5' }}>
            <FaLinkedin />
          </a>
          <a href="https://youtube.com/@barterfunds" style={{ color: '#FF0000' }}>
            <FaYoutube />
          </a>
          <a href="https://instagram.com/barterfunds" style={{ color: '#C13584' }}>
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
        
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;