import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../Header';
import Footer from '../../../Footer';
import Block from '../../components/Block';
import Content from '../../components/Content';
import Head from '../../components/Head';
import Breadcrumb from '../../components/Breadcrumb';
import PageContainer from '../../components/PageContainer';
import BlockHead from '../../components/BlockHead';
import BlockHeadContent from '../../components/BlockHeadContent';
import BlockTitle from '../../components/BlockTitle';

const Support = () => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Help', href: '/help', active: false },
    { label: 'Support Center', href: '#', active: true },
  ];

  const styles = {
    container: { padding: '20px', maxWidth: '1200px', margin: '0 auto' },
    grid: {
      display: 'grid',
      gap: '20px',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
    card: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      margin: '10px',
      border: 'none',
    },
    whatsappButton: { backgroundColor: '#28a745', color: '#fff' },
    telegramButton: { backgroundColor: '#0088cc', color: '#fff' },
    emailButton: { backgroundColor: '#dc3545', color: '#fff' },
    knowledgeBaseButton: { backgroundColor: '#007bff', color: '#fff' },
    faqButton: { backgroundColor: '#ffc107', color: '#fff' },
    communityButton: { backgroundColor: '#17a2b8', color: '#fff' },
    ticketButton: { backgroundColor: '#6c757d', color: '#fff' },
    emoji: { fontSize: '24px', marginRight: '10px' },
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Support Center | Barter Funds</title>

        {/* Meta tags */}

      </Helmet>
      <Header />
      <Head title="Support Center | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs">
          <BlockHeadContent style={{ textAlign: 'center' }}>
            <BlockTitle tag="h2" className="fw-normal">
              Support Center
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PageContainer style={styles.container}>
            <div style={styles.grid}>
              <div style={styles.card}>
                <h4>
                  <span style={styles.emoji}>💬</span>Live Chat
                </h4>
                <p>
                  Need help right now? Our live chat support is available 24/7. Click below to start a conversation.
                </p>
                <a href="https://wa.me/message/54O3YEIW6MH4P1" target="_blank" rel="noopener noreferrer">
                  <button style={{ ...styles.button, ...styles.whatsappButton }}>
                    WhatsApp Chat
                  </button>
                </a>
                <a href="https://t.me/barterfunds" target="_blank" rel="noopener noreferrer">
                  <button style={{ ...styles.button, ...styles.telegramButton }}>
                    Telegram Chat
                  </button>
                </a>
              </div>
              <div style={styles.card}>
                <h4>
                  <span style={styles.emoji}>📧</span>Support Email
                </h4>
                <p>
                  Submit an email, and our team will get back to you as soon as possible.
                </p>
                <a href="mailto:support@barterfunds.com" target="_blank" rel="noopener noreferrer">

                  <button style={{ ...styles.button, ...styles.emailButton }}>

                    Send Email
                  </button>
                </a>
              </div>
              <div style={styles.card}>
                <h4>
                  <span style={styles.emoji}>📚</span>Knowledge Base
                </h4>
                <p>
                  Explore articles, tutorials, and guides to help you navigate our platform.
                </p>
                <a href="/knowledge-base" target="_blank" rel="noopener noreferrer">
                <button style={{ ...styles.button, ...styles.knowledgeBaseButton }}>
                  Visit Knowledge Base
                </button>
                </a>
              </div>
              <div style={styles.card}>
                <h4>
                  <span style={styles.emoji}>❓</span>FAQs
                </h4>
                <p>
                  Find quick answers to common questions in our FAQ section.
                </p>
                <a href="/faq" target="_blank" rel="noopener noreferrer">
                <button style={{ ...styles.button, ...styles.faqButton }}>
                  Browse FAQs
                </button>
                </a>
              </div>
              <div style={styles.card}>
                <h4>
                  <span style={styles.emoji}>👥</span>Community Forums
                </h4>
                <p>
                  Connect with other users to share tips and ask questions.
                </p>
                <a href="https://www.whatsapp.com/channel/0029Va86SiyCRs1rKZ7iFl47" target="_blank" rel="noopener noreferrer">
                  <button style={{ ...styles.button, ...styles.communityButton }}>
                    Visit Community Forums
                  </button>
                </a>
              </div>
              <div style={styles.card}>
                <h4>
                  <span style={styles.emoji}>🎟️</span>Support Ticket
                </h4>
                <p>
                  Submit a ticket for technical issues or in-depth assistance. Our team will review and respond promptly.
                </p>
                <a href="/user/support" target="_blank" rel="noopener noreferrer">
                  <button style={{ ...styles.button, ...styles.ticketButton }}>
                    Submit a Ticket
                  </button>
                </a>
              </div>
            </div>
          </PageContainer>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Support;
