import React, { useState } from "react";
import { Modal } from "antd";
import Camera, { DEVICE, FACING_MODE, PLACEMENT } from 'react-camera-ios';
import 'react-camera-ios/build/styles.css';

const FileUploadWithCamera = ({
  name,
  setSelectedProof,
  buttonText,
  currentFile, // Persisted file passed from parent
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [facingMode, setFacingMode] = useState(FACING_MODE.USER);
  // const isSmallScreen = window.innerWidth <= 768;

  // const toggleCamera = () => {
  //   setFacingMode((prevMode) =>
  //     prevMode === FACING_MODE.USER ? FACING_MODE.ENVIRONMENT : FACING_MODE.USER
  //   );
  // };

  const handleRemoveFile = () => {
    setSelectedProof("", name); // Clear the file from parent state
  };

  const captureFromWebcam = (dataUrl) => {
    if (dataUrl) {
      // Convert the base64 string to a File object
      const byteString = atob(dataUrl.split(",")[1]);
      const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new Uint8Array(byteString.length);

      for (let i = 0; i < byteString.length; i++) {
        arrayBuffer[i] = byteString.charCodeAt(i);
      }

      const file = new File([arrayBuffer], `${name}.jpg`, { type: mimeString });

      setSelectedProof({ file, fileURL: dataUrl }, name); // Persist in parent state
    }

    setIsModalVisible(false); // Close modal after capture
  };

  const titleStyle = {
    textAlign: "center",
    marginBottom: "10px",
  };

  // const containerStyle = { height: '300px', width: '300px' };

  return (
    <>
      <div
        className="nk-kycfm-upload-box align-items-center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="upload-zone dropzone dz-clickable" style={{ display: "block" }}>
          {currentFile ? (
            <div className={`preview-image ${isHovered ? "hovered" : ""}`}>
              <img src={currentFile} alt="Uploaded File" />
              <div className="overlay"></div>
              <button
                className="remove-button"
                onClick={handleRemoveFile}
                style={{ cursor: "pointer" }}
              >
                X
              </button>
            </div>
          ) : (
            <div className="dz-message" data-dz-message="">
              <button
                className="btn btn-primary"
                onClick={() => setIsModalVisible(true)}
                style={{ marginLeft: "10px", margin: "10%" }}
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal
        title={
          <div style={titleStyle}>
            {buttonText}
            <hr />
          </div>
        }
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        width={{
          xs: "90%",
          sm: "80%",
          md: "70%",
          lg: "60%",
          xl: "50%",
          xxl: "40%",
        }}
      >
        <div>
        <div style={{ display:'flex', height: '400px', width: '300px' }}>
          <Camera
            device={DEVICE.MOBILE}
            facingMode={FACING_MODE.USER}
            placement={PLACEMENT.COVER}
            quality="1"
            onError={(error) => console.error(error)}
            onTakePhoto={(dataUrl) => captureFromWebcam(dataUrl)}
          />
</div>

          <div style={{ textAlign: 'center'}}>
           {/* <Button type="primary" onClick={(dataUrl) => captureFromWebcam(dataUrl)} style={{ marginRight: "10px" }}>
              Capture
            </Button> */}
            {/* <Button type="primary" className="mt-4" onClick={toggleCamera} style={{ marginRight: "10px" }}>
              Switch to {facingMode === FACING_MODE.USER ? "Back" : "Front"} Camera
            </Button> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FileUploadWithCamera;
