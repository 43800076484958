import React, { useState, useEffect } from "react";
import "./User.css";
import OpenModal from "./components/OpenModal";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import FileUpload from "./components/FileUpload";
import axios from "axios";
import openNotification from "../components/OpenNotification";


const ReceivePanel = ({
  currencies,
  sendDataToParent,
  formatCurrency,
  setIsLoading,
  accounts,
  user
}) => {
  currencies = currencies?.filter(currency => currency.availableForReceive === true);
  const [formStage, setFormStage] = useState(1);
  const [usdAmount, setUsdAmount] = useState("");
  const [ghsAmount, setGhsAmount] = useState("");
  const [conversionRate, setConversionRate] = useState(
    currencies[0].receiveAt
  );
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
  const [usdInputChangedByUser, setUsdInputChangedByUser] = useState(true);
  const [ghsInputChangedByUser, setGhsInputChangedByUser] = useState(true);
  const [selectedProof, setSelectedProof] = useState('');

  const [receipientMethod, setReceipientMethod] = useState("");
  const [receipientNumber, setReceipientNumber] = useState("");
  const [receipientName, setReceipientName] = useState("");
  const [receipientProvider, setReceipientProvider] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isAccountDropdownEnabled, setIsAccountDropdownEnabled] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [transactionFee, setTransactionFee] = useState(0)
  const transactionType = "receive";

const handleExistingAccount = (event) => {
    const selectedAccount = JSON.parse(event.target.value);

    setSelectedAccountId(selectedAccount._id); 
    setReceipientName(selectedAccount.accountName);
    setReceipientNumber(selectedAccount.accountNumber);
    setReceipientProvider(selectedAccount.accountProvider);
  };

const handleReceipientMethodChange = (event) => {
  const method = event.target.value;
  setReceipientMethod(method);
  setReceipientNumber('');
  
  // Enable account selection for 'momo' and 'bank' methods
  if (method === "momo" || method === "bank") {
    setIsAccountDropdownEnabled(true);
  } else {
    setIsAccountDropdownEnabled(false);
    setAccountStatus("");
    setReceipientName("");
    setReceipientProvider("");
  }
};

const handleAccountStatusChange = (event) => {
  setAccountStatus(event.target.value);
  setSelectedAccountId("");
  setReceipientNumber("");
  setReceipientName("");
  setReceipientProvider("");
};

  useEffect(() => {
    // Convert USD to GHS only if input changed by user
    if (usdAmount !== "" && usdInputChangedByUser) {
      const ghsValue = parseFloat(usdAmount) * conversionRate;
      setGhsAmount(ghsValue.toFixed(2));
    }
  }, [usdAmount, conversionRate, usdInputChangedByUser]);

  useEffect(() => {
    // Convert GHS to USD only if input changed by user
    if (ghsAmount !== "" && ghsInputChangedByUser) {
      const usdValue = parseFloat(ghsAmount) / conversionRate;
      setUsdAmount(usdValue.toFixed(2));
    }
  }, [ghsAmount, conversionRate, ghsInputChangedByUser]);

  const handleUsdInputChange = (event) => {
    const value = event.target.value;

    if (value < 0) {
      return;
    }

    setUsdAmount(value);
    setUsdInputChangedByUser(true);
    setGhsInputChangedByUser(false);

    if (value === "") {
      setGhsAmount("");
    }
  };

  const handleGhsInputChange = (event) => {
    const value = event.target.value;

    if (value < 0) {
      return;
    }

    setGhsAmount(value);
    setGhsInputChangedByUser(true);
    setUsdInputChangedByUser(false);

    if (value === "") {
      setUsdAmount("");
    }
  };

  const nextFormStage = () => {
    if (formStage === 1) {
      // Check if required fields in stage 1 have values
      if (!ghsAmount || !usdAmount) {
        openNotification("topRight", "error", "Error", "Please fill in all required fields.");
        return;
      }

      const ghsValue = parseFloat(ghsAmount);
      const usdValue = parseFloat(usdAmount);
      const minAmount = selectedCurrency.minimumReceiveAmount || 100.00;
      const maxAmount = selectedCurrency.maximumReceiveAmount || 100.00;
      


      if (ghsValue < minAmount) {
        openNotification("topRight", "error", "Error", `The amount must be greater than the minimum amount of GHS ${formatCurrency(minAmount)}.`);

        return;
      }

      if (ghsValue > maxAmount) {
        openNotification("topRight", "error", "Error", `The amount must be less than the maximum amount of GHS ${formatCurrency(maxAmount)}.`);
        return;
      }

      if(selectedCurrency.receiveFixedCharge > 0){
        setTransactionFee(selectedCurrency.receiveFixedCharge)
      } else{
      setTransactionFee((selectedCurrency.receivePercentCharge / 100) * usdValue)
      }

    } else if (formStage === 2) {
      // Check if required fields in stage 2 have values
      if ((receipientMethod !== 'wallet') && (!receipientMethod || !receipientNumber || !receipientName || !receipientProvider)) {
        openNotification("topRight", "error", "Error", "Please select an account or fill in all required fields.");
        return;
      }
    }

    // Proceed to the next stage if all required fields have values
    setFormStage((prevStage) => {
      sendDataToParent(prevStage + 1);
      return prevStage + 1;
    });
  };

  const previousFormStage = () => {
    setFormStage((prevStage) => {
      sendDataToParent(prevStage - 1);
      return prevStage - 1;
    });
  };

  const selectCurrency = (currency) => {
    setSelectedCurrency(currency);
    setConversionRate(currency.receiveAt);
  };

  const handleOk = () => {
    setIsLoading(true);
    const body = new FormData();
    const token = window.sessionStorage.getItem("token");
    const transactionId = window.sessionStorage.getItem("transactionId");

    const headers = {
        Authorization: `Bearer ${token}`,
      };


      body.append('paymentProof', selectedProof);

      axios
      .patch(`${process.env.REACT_APP_API_URL}/transactions/${transactionId}`, body, { headers: headers })
      .then((updateResult) => {
        
        if (updateResult.data.success) {
          openNotification(
            "topRight",
            "success",
            "Success",
            "Payment Proof uploaded successfully"
          );

          window.sessionStorage.removeItem('transactionId');

          setTimeout(() => {
            window.location.href = `/user/transactions`;
          }, 1000);
        } else {
          openNotification(
            "topRight",
            "error",
            "Error",
            "Failed to upload payment proof"
          );
        }
      })
      .catch((updateError) => {
        openNotification(
          "topRight",
          "error",
          "Error",
          "Failed to upload payment proof"
        );
        console.log("cannot update transaction id")
      });  

  };

  const truncateWord = (word) => {
    if (word.length > 10) {
        return word.slice(0, 10) + '...';
    }
    return word;
  }

  return (
    <>
      {formStage === 1 && (
        <div
          className={`form-stage ${formStage === 1 ? "visible" : ""}`}
          id="stage1"
        >
          <div className="buysell-block">
            <form action="#" className="buysell-form">
              <div className="buysell-field form-group form-section">
                <div className="buysell-field form-group">
                  <div className="form-label-group">
                    <label className="form-label">
                      Choose what you want to get
                    </label>
                  </div>

                  <div className="dropdown buysell-cc-dropdown">
                    <span
                      className="buysell-cc-choosen dropdown-indicator"
                      data-bs-toggle="dropdown"
                    >
                      <div
                        className={`coin-item coin-${selectedCurrency.currencyCode}`}
                      >
                        <div className="coin-icon">
                          <img
                            src={selectedCurrency.currencyLogo}
                            alt={`${selectedCurrency.currencyCode.toUpperCase()} (${selectedCurrency.currencyCode.toUpperCase()})`}
                          />
                        </div>
                        <div className="coin-info">
                          <span className="coin-name">
                            {selectedCurrency.currencyName}
                          </span>
                          <span className="coin-text">
                            {selectedCurrency.currencyCode}
                          </span>
                        </div>
                      </div>
                    </span>
                    <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                      <ul className="buysell-cc-list">
                        {currencies.map((currency) => (
                          <li
                            className={`buysell-cc-item ${
                              selectedCurrency === currency ? "selected" : ""
                            }`}
                            key={currency.currencyCode}
                          >
                            <span
                              className="buysell-cc-opt"
                              data-currency={currency.currencyCode}
                              onClick={() => selectCurrency(currency)}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div
                                className={`coin-item coin-${currency.currencyCode}`}
                              >
                                <div className="coin-icon">
                                  <img
                                    src={currency.currencyLogo}
                                    alt={`${currency.currencyCode.toUpperCase()} (${currency.currencyCode.toUpperCase()})`}
                                  />
                                </div>
                                <div className="coin-info">
                                  <span className="coin-name">
                                    {currency.currencyName}
                                  </span>
                                  <span className="coin-text">
                                    {currency.currencyCode}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="buysell-field form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="usd-input">
                      Barter Funds will receive{" "}
                      <Tooltip
                        placement="right"
                        title={"This is a required field"}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </label>
                  </div>
                  <div className="currency-box">
                    <input
                      type="number"
                      className="form-control form-control-lg form-control-number"
                      id="usd-input"
                      name="usd-input"
                      placeholder={`Amount in ${selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"}`}
                      value={usdAmount}
                      onChange={handleUsdInputChange}
                      required
                    />
                    <span className="currency-symbol">&nbsp;{selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"}</span>
                    <img
                      src={selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "/assets/images/currency/cny.png" : "/assets/images/payment/usd-icon.png"}
                      alt={selectedCurrency.currencyName.includes('yuan') ? "Chinese Yuan (CNY)" : "US Dollar (USD)"}
                    />
                  </div>
                  &nbsp;
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="ghs-input">
                      I will receive{" "}
                      <Tooltip
                        placement="right"
                        title={"This is a required field"}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </label>
                  </div>
                  <div className="currency-box">
                    <input
                      type="number"
                      className="form-control form-control-lg form-control-number"
                      id="ghs-input"
                      name="ghs-input"
                      placeholder="Amount in GHS"
                      value={ghsAmount}
                      onChange={handleGhsInputChange}
                      required
                    />
                    <span className="currency-symbol">&nbsp;GHS</span>
                    <img
                      src="/assets/images/payment/cedis-icon.png"
                      alt="Ghana Cedis (GHS)"
                    />
                  </div>
                  <div className="form-note-group">
                    <span className="buysell-min form-note-alt">
                      Max:{" "}
                      {selectedCurrency.minimumReceiveAmount
                        ? formatCurrency(selectedCurrency.minimumReceiveAmount)
                        : formatCurrency(100.0)}{" "}
                      GHS
                    </span>
                    <span className="buysell-max form-note-alt">
                      Min:{" "}
                      {selectedCurrency.maximumReceiveAmount
                        ? formatCurrency(selectedCurrency.maximumReceiveAmount)
                        : formatCurrency(100.0)}{" "}
                      GHS
                    </span>
                    <span className="buysell-rate form-note-alt">
                      1 {selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"} = {formatCurrency(conversionRate)} GHS
                    </span>
                  </div>
                </div>

                <div className="form-navigation">
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                    onClick={nextFormStage}
                  >
                    Continue To Receive
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

{formStage === 2 && (
    <div className={`form-stage ${formStage === 2 ? "visible" : ""}`} id="stage2">
      <div className="buysell-field form-group">
        <div className="form-navigation">
          <label type="button" onClick={previousFormStage}>
            <i className="icon la la-arrow-left" />
          </label>
        </div>

        <div className="buysell-field form-group">
          <div className="form-label-group">
            <label className="form-label">I want to receive via <Tooltip placement="right" title={"This is a required field"}><QuestionCircleOutlined /></Tooltip></label>
          </div>
          <div className="buysell-pm-list">
            {/* Payment method selection */}
            <li className="buysell-pm-item">
              <input
                className="buysell-pm-control"
                type="radio"
                name="bs-method"
                id="pm-momo"
                value="momo"
                checked={receipientMethod === "momo"}
                onChange={handleReceipientMethodChange}
              />
              <label className="buysell-pm-label" htmlFor="pm-momo">
                <span className="pm-name">Mobile Money</span>
                <span className="pm-icon">
                  <em className="icon la la-money" />
                </span>
              </label>
            </li>

            <li className="buysell-pm-item">
              <input
                className="buysell-pm-control"
                type="radio"
                name="bs-method"
                id="pm-bank"
                value="bank"
                checked={receipientMethod === "bank"}
                onChange={handleReceipientMethodChange}
              />
              <label className="buysell-pm-label" htmlFor="pm-bank">
                <span className="pm-name">Bank Transfer</span>
                <span className="pm-icon">
                  <em className="icon la la-bank" />
                </span>
              </label>
            </li>

            <li className="buysell-pm-item">
              <input
                className="buysell-pm-control"
                type="radio"
                name="bs-method"
                id="pm-wallet"
                value="wallet"
                checked={receipientMethod === "wallet"}
                onChange={handleReceipientMethodChange}
              />
              <label className="buysell-pm-label" htmlFor="pm-wallet">
                <span className="pm-name">Barter Wallet</span>
                <span className="pm-icon">
                  <em className="icon la la-wallet" />
                </span>
              </label>
            </li>
          </div>
        </div>

        {/* Account selection dropdown */}
        <div className="form-label-group">
          <label className="form-label">Select Account <Tooltip placement="right" title={"Select user account"}><QuestionCircleOutlined /></Tooltip></label>
        </div>
        <div className="currency-box">
          <select 
            className="form-select form-control"
            disabled={!isAccountDropdownEnabled}
            onChange={handleAccountStatusChange}
            
          >
            <option value="">Select Account</option>
            <option value="exist" selected={accountStatus === 'exist'}>Use Existing {receipientMethod === "momo" ? "Mobile" : "Bank"} Account</option>
            {accounts.length < 5 && (
            <option value="new" selected={accountStatus === 'new'}>Use New {receipientMethod === "momo" ? "Mobile" : "Bank"} Account</option>

            )}
          </select>
        </div>

        {/* Existing account list */}
<div className="mb-3">
        {accountStatus === "exist" && (
          <div className="form-label-group mt-3">
            <label className="form-label">Select {receipientMethod === 'momo' ?  "Mobile" : "Bank"} Account to receive with</label>
          </div>
        )}

{accountStatus === "exist" && (
  <div>
    {
      accounts.filter(account => account.type === receipientMethod).length !== 0 ? (
        accounts.filter(account => account.type === receipientMethod).map((account) => {
          return (
            <div key={account._id}>
              <input 
                type="radio" 
                className="form-radio" 
                name="number" 
                value={JSON.stringify(account)} 
                style={{ marginRight: "5px" }} 
                onChange={handleExistingAccount} 
                checked={selectedAccountId === account._id}
              />
              <label className="form-label">{account?.accountNumber} -{" "}
              {account?.accountProvider}</label>
            </div>
          );
        })
      ) : (
        <p className="text-secondary">You have no existing {receipientMethod === 'momo' ? "mobile" : "bank"} accounts</p>
      )
    }
  </div>
)}

        {/* New account form */}
        {accountStatus === "new" && (
          <>
            <div className="form-label-group mt-3">
              <label className="form-label">Enter {receipientMethod === 'momo' ?  "Mobile" : "Bank"} Account Details</label>
            </div>

            {receipientMethod === 'momo' ?  (
              <div className="col-auto">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">Mobile Network</div>
                </div>
                <select className="form-select form-control" onChange={((e) => {setReceipientProvider(e.target.value)})}>
                  <option value="">Select Mobile Network</option>
                  <option value="MTN">MTN</option>
                  <option value="Telecel">Telecel</option>
                  <option value="AirtelTigo">AirtelTigo</option>
                </select>
              </div>
            </div>

            ) : (
              <div className="col-auto">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">Bank Name</div>
                </div>
                <select className="form-select form-control" onChange={((e) => {setReceipientProvider(e.target.value)})}>
                <option value="">Select Bank</option>
                <option value="Absa Bank Ghana Limited">Absa Bank Ghana Limited</option>
                <option value="Access Bank Ghana Plc">Access Bank Ghana Plc</option>
                <option value="Agricultural Development Bank of Ghana">Agricultural Development Bank of Ghana</option>
                <option value="Bank of Africa Ghana Limited">Bank of Africa Ghana Limited</option>
                <option value="CalBank Limited">CalBank Limited</option>
                <option value="Consolidated Bank Ghana Limited">Consolidated Bank Ghana Limited</option>
                <option value="Ecobank Ghana Limited">Ecobank Ghana Limited</option>
                <option value="FBN Bank Ghana Limited">FBN Bank Ghana Limited</option>
                <option value="Fidelity Bank Ghana Limited">Fidelity Bank Ghana Limited</option>
                <option value="First Atlantic Bank Limited">First Atlantic Bank Limited</option>
                <option value="First National Bank Ghana">First National Bank Ghana</option>
                <option value="GCB Bank Limited">GCB Bank Limited</option>
                <option value="Guaranty Trust Bank Ghana Limited">Guaranty Trust Bank Ghana Limited</option>
                <option value="National Investment Bank Limited">National Investment Bank Limited</option>
                <option value="OmniBSIC Bank Ghana Limited">OmniBSIC Bank Ghana Limited</option>
                <option value="Prudential Bank Limited">Prudential Bank Limited</option>
                <option value="Republic Bank Ghana Limited">Republic Bank Ghana Limited</option>
                <option value="Société Générale Ghana Limited">Société Générale Ghana Limited</option>
                <option value="Stanbic Bank Ghana Limited">Stanbic Bank Ghana Limited</option>
                <option value="Standard Chartered Bank Ghana Limited">Standard Chartered Bank Ghana Limited</option>
                <option value="United Bank for Africa Ghana Limited">United Bank for Africa Ghana Limited</option>
                <option value="Universal Merchant Bank Limited">Universal Merchant Bank Limited</option>
                <option value="Zenith Bank Ghana Limited">Zenith Bank Ghana Limited</option>
              </select>
              </div>
            </div>

            )}
            

            <div className="col-auto">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">{receipientMethod === 'momo' ?  "Mobile" : "Bank"} Account Number</div>
                </div>
                <input type="text" className="form-control" placeholder={receipientMethod === 'momo' ?  "Enter mobile number" : "Enter account number"} onChange={((e) => {setReceipientNumber(e.target.value)})}/>
              </div>
            </div>

            <div className="col-auto">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">{receipientMethod === 'momo' ?  "Mobile" : "Bank"} Account Name</div>
                </div>
                <input type="text" className="form-control" placeholder="Enter account name" onChange={((e) => {setReceipientName(e.target.value)})} />
              </div>
            </div>
          </>
        )}

</div>

        <div className="form-navigation">
          <button
            type="button"
            className="btn btn-lg btn-block btn-primary"
            onClick={nextFormStage}
          >
            Continue To Sell
          </button>
        </div>
      </div>
    </div>
  )}

      {formStage === 3 && (
        <div
          className={`form-stage ${formStage === 3 ? "visible" : ""}`}
          id="stage3"
        >
          <div className="form-navigation">
            <label type="button" onClick={previousFormStage}>
              <i className="icon la la-arrow-left" />
            </label>
          </div>

          <div className="nk-block-head nk-block-head-xs text-center">
            <h5 className="nk-block-title">Confirm Order</h5>
            <div className="nk-block-text">
              <div className="caption-text">
                BarterFunds will receive
                <strong>
                  {" "}
                  <strong>{formatCurrency(usdAmount)}</strong> {selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"} of
                  {" "}{selectedCurrency.currencyName}
                </strong>{" "}
                
              </div>
              <span className="sub-text-sm">
                Exchange rate: 1 {selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"} =
                {formatCurrency(conversionRate)} GHS
              </span>
            </div>
          </div>
          <div className="nk-block">
            <div className="buysell-overview">
              <ul className="buysell-overview-list">
                <li className="buysell-overview-item">
                  <span className="pm-title">Receive via</span>
                  <span className="pm-currency">
                    {receipientMethod === "momo" ? (
                      <>
                        <em className="icon la la-money" />
                        <span>Mobile Money</span>
                      </>
                    ) : receipientMethod === "bank" ? (
                      <>
                        <em className="icon la la-bank" />
                        <span>Bank Transfer</span>
                      </>
                    ) : (
                      <>
                        <em className="icon la la-wallet" />
                        <span>Barter Wallet</span>
                      </>
                    )}
                  </span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">{receipientMethod === 'momo' ? "Mobile Number" : receipientMethod === 'bank' ? "Account Number" : "Wallet Address"}</span>
                  <span className="pm-currency">{receipientNumber ? truncateWord(receipientNumber) : 'Barter Wallet' }</span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">Account Provider</span>
                  <span className="pm-currency">{receipientProvider ? receipientProvider : "BarterFunds"}</span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">Account Name</span>
                  <span className="pm-currency">{receipientName ? receipientName : `${user?.firstname} ${user?.surname}`}</span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">Sub Total</span>
                  <span className="pm-currency">{formatCurrency(usdAmount)} {selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"}</span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">Transaction Fee</span>
                  <span className="pm-currency">{formatCurrency(transactionFee)} {selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"}</span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">Total Amount</span>
                  <span className="pm-currency">{formatCurrency(Number(usdAmount) + Number(transactionFee))} {selectedCurrency.currencyCode.toLowerCase().includes('rmb') ? "RMB" : "USD"}</span>
                </li>
              </ul>
              <div className="sub-text-sm text-center">
                * Our transaction fee are included.
                <a href={"/fees"}> See transaction fee</a>
              </div>
            </div>
            {/* <div className="form-navigation">
              <button
                type="button"
                className="btn btn-lg btn-block btn-primary"
                onClick={nextFormStage}
              >
                Confirm Your Order
              </button>
            </div> */}

            <OpenModal
              title={"Confirm your Order"}
              usdAmount={usdAmount}
              ghsAmount={ghsAmount}
              transactionFee={transactionFee}
              selectedCurrency={selectedCurrency}
              receipientMethod={receipientMethod}
              receipientNumber={receipientNumber}
              transactionType={transactionType}
              setIsLoading={setIsLoading}
              nextFormStage={nextFormStage}
              formatCurrency={formatCurrency}
              exchangeRate={conversionRate}
              receipientName={receipientName}
              receipientProvider={receipientProvider}
            />
          </div>
          {/* .nk-block */}
        </div>
      )}

      {formStage === 4 && (
        <div
          className={`form-stage ${formStage === 4 ? "visible" : ""}`}
          id="stage4"
        >
          <div className="nk-modal text-center">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl la la-check bg-success" />
            <h4 className="nk-modal-title">Order Successfully Made!</h4>
            <div className="nk-modal-text">
              <p className="caption-text">
                You will receive <strong>{formatCurrency(Number(ghsAmount) - Number(transactionFee * conversionRate))} GHS</strong>
              </p>
              <p className="sub-text-sm">
                Once we receive the payment from your sender.
              </p>
            </div>
            <div className="nk-modal-action-lg">
              <ul className="btn-group gx-4">
                <li>
                  <button
                    type="button"
                    className="btn btn-lg btn-mw btn-primary"
                    onClick={nextFormStage}
                  >
                    Upload Payment Screenshot
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div className="text-center w-100">
            <p>
              Earn up to GHS 50 for each friend your refer!{" "}
              <a href={"/user/dashboard"}>Invite friends</a>
            </p>
          </div>
        </div>
      )}

      {formStage === 5 && (
        <div
          className={`form-stage ${formStage === 5 ? "visible" : ""}`}
          id="stage5"
        >
          <div className="buysell-field form-group">
            <div className="form-navigation">
              <label type="button" onClick={previousFormStage}>
                <i className="icon la la-arrow-left" />
              </label>
            </div>

            <div className="text-center">
              <p>
                Please upload a screenshot proof of your payment. If you don't
                have the screenshot now, you can go to your orders page and
                upload it later.
              </p>
            </div>
          </div>
          <div className="buysell-field form-group">
            <FileUpload name={"screenshot"} id={"screenshot"} setSelectedProof={setSelectedProof} setSubmitButton={setSubmitButton} />
          </div>

          <div className="form-navigation">
          <button
            type="button"
            className="btn btn-lg btn-block btn-primary"
            onClick={handleOk}
            disabled={!submitButton}
        >
            Submit Screenshot
        </button>
          </div>

          <div className="text-center my-3">
            <a href={`/user/transactions`}>
              I will upload later
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default ReceivePanel;
